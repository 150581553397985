/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, TitleMain, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Image, Text, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <ColumnCover name={"menu"}>
          
          <Column className="--menu --style4 pb--16 pt--16" menu={true}>
            
            <Menu className="--full pb--06 pt--06" style={{"maxWidth":1540}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box">
                
                <TitleMain className="title-box fs--24 w--600 title-box--invert swpf--uppercase" content={"<span style=\"color: rgb(238, 77, 13);\">Půjčovna minibagrů</span><br>"}>
                </TitleMain>

                <Subtitle className="subtitle-box fs--20 subtitle-box--invert mt--02" content={"Plzeňský kraj"}>
                </Subtitle>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 w--600 swpf--uppercase lh--16 pl--08 pr--06" innerClassName="pl--0 pr--0" href={"#technika-2"} target={""} content={"naše technika"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 w--600 swpf--uppercase lh--16 pl--08 pr--08" innerClassName="pl--0 pr--0" href={"#kontakt"} target={""} content={"kontaktovat"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn1 btn-box--filling2 w--600 swpf--uppercase lh--16 pl--08 pr--08" innerClassName="pb--12 pl--12 pr--12 pt--10" href={"#poptat"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">poptat</span>"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="css-963koh css-42e4bw --style4 --center --full" name={"uvod"} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/13064/38af77ba8d0f4d7a80b81069c83ca05c_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/13064/38af77ba8d0f4d7a80b81069c83ca05c_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/13064/38af77ba8d0f4d7a80b81069c83ca05c_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/13064/38af77ba8d0f4d7a80b81069c83ca05c_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/13064/38af77ba8d0f4d7a80b81069c83ca05c_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/13064/38af77ba8d0f4d7a80b81069c83ca05c_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/13064/38af77ba8d0f4d7a80b81069c83ca05c_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/13064/38af77ba8d0f4d7a80b81069c83ca05c_s=3000x_.jpeg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left mt--40" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--36 subtitle-box--invert mt--20" content={"<br>"}>
              </Subtitle>

              <Title className="title-box fs--102 title-box--invert" content={"<span style=\"font-weight: bold;\">Minibagry-Plzeňsko</span><br>"}>
              </Title>

              <Subtitle className="subtitle-box fs--43 subtitle-box--invert mt--20" content={"<span style=\"font-weight: bold;\">Značková půjčovna profesionálních minibagrů Kubota a techniky pro zemní práce svépomocí Plzeňsko.&nbsp;<br>Provádíme zemní výkopové práce službou v krátkých termínech.</span><br>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--filling2 fs--22 swpf--uppercase mt--30" href={"#technika-2"} content={"<span style=\"color: rgb(255, 255, 255);\">NAŠE TECHNIKA</span><br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"technika"}>
        </Column>


        <Column className="pb--60 pl--40 pr--40" name={"technika-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --left el--3 --full pb--20 pl--16 pr--16 pt--10" anim={"2"} animS={"5"} columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Půjčovna minibagrů"} src={"https://cdn.swbpg.com/t/13064/97a5709c987544d5a86ba4550a188f04_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, 33vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/13064/97a5709c987544d5a86ba4550a188f04_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13064/97a5709c987544d5a86ba4550a188f04_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13064/97a5709c987544d5a86ba4550a188f04_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13064/97a5709c987544d5a86ba4550a188f04_s=1400x_.jpg 1400w"} position={{"x":"0%","y":"0%"}}>
              </Image>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Minibagr Kubota U10-5 naklápěcí hlava</span>"}>
              </Subtitle>

              <Text className="text-box mt--02" style={{"maxWidth":467}} content={"<br>"}>
              </Text>

              <Text className="text-box mt--16" style={{"maxWidth":467}} content={"<span style=\"font-weight: bold;\">Oblíbený joystickový stroj s naklápěcí hlavou, průjezd 85 cm, hloubkový dosah 190 cm. Lopaty 20,40,80 cm.<br>2600,- Kč/den</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr3 swpf--uppercase mt--25" href={"#poptat"} content={"Poptat"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Půjčovna nakladačů"} src={"https://cdn.swbpg.com/t/13064/26e52449ebbf437e8ebf455e9a4b041b_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, 33vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/13064/26e52449ebbf437e8ebf455e9a4b041b_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/26e52449ebbf437e8ebf455e9a4b041b_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/26e52449ebbf437e8ebf455e9a4b041b_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13064/26e52449ebbf437e8ebf455e9a4b041b_s=1400x_.jpeg 1400w"} position={{"x":"0%","y":"-3.2983508245877067%"}}>
              </Image>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Minibagr Kubota K008-5</span>"}>
              </Subtitle>

              <Text className="text-box mt--02" style={{"maxWidth":467}} content={"<br>"}>
              </Text>

              <Text className="text-box mt--16" style={{"maxWidth":467}} content={"<span style=\"font-weight: bold;\">Základní model ovládaný páčkami, průjezd 71 cm, lopaty 20,40,80 cm.<br>2200,- Kč/den</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr3 swpf--uppercase mt--25" href={"#poptat"} content={"Poptat"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Půjčovna výkopové techniky"} src={"https://cdn.swbpg.com/t/13064/0aaedc7625e84765b412497f569cb898_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, 33vw" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/13064/0aaedc7625e84765b412497f569cb898_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13064/0aaedc7625e84765b412497f569cb898_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13064/0aaedc7625e84765b412497f569cb898_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13064/0aaedc7625e84765b412497f569cb898_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13064/0aaedc7625e84765b412497f569cb898_s=2000x_.jpg 2000w"} position={{"x":"0%","y":"0%"}}>
              </Image>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Minidumpery Cormidi 14.85 ACW</span>"}>
              </Subtitle>

              <Text className="text-box mt--02" style={{"maxWidth":467}} content={"<br>"}>
              </Text>

              <Text className="text-box mt--16" style={{"maxWidth":467}} content={"<span style=\"font-weight: bold;\">Samonakládací &nbsp;minidumper &nbsp;nosnost 800 kg, průjezd 85 cm, Motorizace Kubota.<br>2300,- Kč/den</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr3 swpf--uppercase mt--25" href={"#poptat"} content={"Poptat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-v1wlq6 --style3 --full --parallax pl--40 pr--40" name={"kontakt"} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/13064/959bc88585e04536939ebe7397836f8d_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/13064/959bc88585e04536939ebe7397836f8d_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/13064/959bc88585e04536939ebe7397836f8d_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/13064/959bc88585e04536939ebe7397836f8d_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/13064/959bc88585e04536939ebe7397836f8d_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/13064/959bc88585e04536939ebe7397836f8d_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/13064/959bc88585e04536939ebe7397836f8d_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/13064/959bc88585e04536939ebe7397836f8d_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--3 --full pl--16 pr--16 flex--center" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4 --left pb--40 pl--40 pr--40 pt--40" anim={"7"} animS={"4"} style={{"maxWidth":1280,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box fs--30 lh--14" content={"<span style=\"font-weight: bold;\">Příslušenství, které jinde nenajdete.</span><br>"}>
              </Subtitle>

              <Text className="text-box" content={"Půjčíme Vám i  příslušenství k minibagru, vrtací jednotky, bourací kladiva, hutnicí techniku a další. Vše Vám urychlí a zpříjemní práci."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim7 --anim-s4 --left pb--40 pl--40 pr--40 pt--40" anim={"7"} animS={"4"} style={{"maxWidth":1280,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box fs--30 lh--14" content={"<span style=\"font-weight: bold;\">Poradíme Vám.&nbsp;<br>Minibagry dostupné ihned.</span><br>"}>
              </Subtitle>

              <Text className="text-box" content={"Stroj Vám půjčíme 7 dnů v týdnu včetně víkendů a svátků. Řešíte akutní havárii nutně potřebujete minibagr? Jsme schopni zapůjčit minibagr v řádu hodiny.\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim7 --anim-s4 --left pb--40 pl--40 pr--40 pt--40" anim={"7"} animS={"4"} style={{"maxWidth":1280,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Subtitle className="subtitle-box fs--30 lh--14" content={"<span style=\"font-weight: bold;\">Obsluhovat stroje Vás samozřejmě naučíme.</span><br>"}>
              </Subtitle>

              <Text className="text-box" content={"Každý stroj má svůj vlastní přepravní vlek. Nic nepřekládáme. K osvojení techniky práce obdržíte po objednání předem videonávody."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"poptat"}>
          
          <ColumnWrap className="column__flex el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Poptávka"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":591}} content={"<span style=\"font-weight: bold;\">Poptejte nezávazně naše služby – půjčení minibagru a další. </span><br><span style=\"font-weight: bold;\">Budeme Vás </span><span style=\"font-weight: 700;\">kontaktovat</span><span style=\"font-weight: bold;\">&nbsp;a na všem se dohodneme.</span><br><span style=\"font-weight: bold;\">Pokud se Vám nechce psát volejte tel. 724 277 450</span><br>"}>
              </Text>

              <ContactForm className="--shape2 --style3 fs--20 w--300 lh--14 mt--30" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno","id":"8sbr4go4b1z8"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail","id":"26e9egfgtqqo"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420","id":"nk6hv1b4okkp"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy","id":"o0kdcnpnaind"},{"name":"Odeslat","type":"submit","id":"2t8l9qsjoo0o"}]} layout={"l2"}>
              </ContactForm>

              <Text className="text-box fs--16 lh--14 mt--12" style={{"maxWidth":477}} content={"Odesláním formuláře souhlasíte se zpracovaním osobních údajů. Budeme Vás brzy kontaktovat!"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s5" anim={"4"} animS={"5"}>
              
              <Image src={"https://cdn.swbpg.com/t/13064/278fed94861e4633b56fdb566542ed28_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/13064/278fed94861e4633b56fdb566542ed28_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/278fed94861e4633b56fdb566542ed28_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/278fed94861e4633b56fdb566542ed28_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13064/278fed94861e4633b56fdb566542ed28_s=1400x_.jpeg 1400w"}>
              </Image>

              <Text className="text-box text-box--right fs--16 lh--16 mt--50" style={{"maxWidth":""}} content={"Jsme specialisté&nbsp; v oblasti půjčení minibagru a výkopové techniky."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-ww59o1 css-42e4bw --style2 --full --parallax" name={"kontakt"} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/13064/54ba59ef1b2f443f8f191f9a6ef98f72_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/13064/54ba59ef1b2f443f8f191f9a6ef98f72_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/13064/54ba59ef1b2f443f8f191f9a6ef98f72_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/13064/54ba59ef1b2f443f8f191f9a6ef98f72_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/13064/54ba59ef1b2f443f8f191f9a6ef98f72_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/13064/54ba59ef1b2f443f8f191f9a6ef98f72_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/13064/54ba59ef1b2f443f8f191f9a6ef98f72_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/13064/54ba59ef1b2f443f8f191f9a6ef98f72_s=3000x_.jpeg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4" anim={"7"} animS={"4"} style={{"maxWidth":1280}}>
              
              <Title className="title-box fs--72 title-box--invert" content={"Potřebujete poradit?"}>
              </Title>

              <Text className="text-box fs--18 text-box--invert lh--14 mt--02" content={"<br>"}>
              </Text>

              <Subtitle className="subtitle-box fs--30 subtitle-box--invert lh--14 mt--20" content={"<span style=\"font-weight: bold;\">Volejte 724 277 450</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"paticka"} style={{"backgroundColor":"rgba(18, 18, 18, 1)"}}>
          
          <ColumnWrap className="column__flex el--3 pb--16 pl--25 pr--25 pt--10" style={{"maxWidth":1280}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--24 w--600 title-box--invert swpf--uppercase" content={"<span style=\"color: var(--color-variable-1);\">půjčovna minibagrů<br>Kubota domů<br></span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--20 subtitle-box--invert mt--02" content={"Plzeňský kraj"}>
              </Subtitle>

              <Text className="text-box fs--14 mt--08" content={"Služby nabízíme zejména pro oblasti:<br>"}>
              </Text>

              <Text className="text-box fs--16 lh--14 mt--02" content={"Plzeň, Příbram, Písek, Klatovy, Rokycany, Cheb, Karlovy Vary, Strakonice, České Budějovice, Tábor, Domažlice, Přeštice, Stod, Stříbro, Nýřany, Tlučná, Vejprnice, Nepomuk, Rokycany a další.<br>"}>
              </Text>

              <Text className="text-box fs--14 mt--0" content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Hledáte rychlou realizaci prací menšího rozsahu u Vás doma? Nechce se Vám už čekat na dlouhé objednací termíny? Půjčte si špičkový minibagr Kubota. Bez komplikací. Služba pro lidi.<br></span><br>"}>
              </Text>

              <Text className="text-box fs--14 mt--0" content={"&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"#uvod"} content={"Úvod"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"#technika-2"} content={"Naše technika"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"#kontakt"} content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}